
.file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 100px;
    width: 100%;
    background-color: #eff5f5;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  
  .file-drop > .file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    
    /* --------------- */
  
    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
  
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
  
    text-align: center;
    /* --------------- */
  }
  
  .file-drop > .file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    border: none;
    background-color: rgba(3, 163, 244, 0.2);
    box-shadow: none;
    z-index: 50;
    opacity: 1.0;
    /* --------------- */
  
    /* typography */
    color: black;
    /* --------------- */
  }
  
  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    color: black;
    box-shadow: 0 0 13px 3px rgba(0, 0,0, 0.1);
  }

/* --------------- */
/* button */
/* --------------- */


.file-upload {
	position: relative;
    display: inline-block;
    margin-top: 2px;
}

.file-upload > .file-upload__label {
  display: block;
  padding: .5em 1.5em .5em 1.5em;
  color: white;
  font-size: 0.875rem;
font-weight: 500;
font-family: Roboto;
  background: #012a38;
  border-radius: .4em;
  transition: background .3s;
  
  &:hover {
     cursor: pointer;
  }
}
    
.file-upload > .file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width:0;
    height: 100%;
    opacity: 0;
}