.layout-compact-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
}

.layout-compact-main {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  height: 100%;
  min-width: 1px;
}

.layout-compact-content-wrapper {
  // padding-left: 26px;
  // padding-right: 26px;
  flex: 1 1 100%;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  // max-width: 1440px;

}

.layout-compact-content {
  margin: 0;
  width: 100%;
  max-width: 1440px;
}

@media only screen and (max-width: 960px) {
  .layout-compact-main {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 100%;
  }
}
